import red from '@material-ui/core/colors/red';
import ConduitITC from './fonts/Conduit_ITC.ttf';
import ConduitITCBoldItalic from './fonts/ConduitITC-BoldItalic.ttf';
import { lighten } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const primary = '#5F4A8C';
export const secondary = '#5b526d';
export const toolbarBackground = '#5F4A8C';
export const sidebarBackground = '#271B40';
export const white = '#FFFFFF';
export const componentColor = '#5dbdb0';
export const orange = "#eb9457";
export const tertiary = "#5dbdb0"
export const yellow = "#f8c523";
export const lightRed = "#f03131";
export const quaternary = grey[400]
export const switchDisabledColor = grey[400]
export const switchFalseColor = "#818181"

const conduit = {
  fontFamily: 'Conduit',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Conduit'),
    url(${ConduitITC}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const conduitBoldItalic = {
  fontFamily: 'ConduitBoldItalic',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 'bold',
  src: `
    local('Conduit'),
    url(${ConduitITCBoldItalic}) format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

// Create a theme instance.
const theme = { 
  typography: {
    fontFamily: 'Conduit, Arial',
    h3: {
      fontFamily: 'ConduitBoldItalic, Arial',
      fontWeight: 'bold',
      fontStyle: 'italic',
      textTransform: 'uppercase',
      fontSize: '1.25rem'
    },
    h4: {
      fontFamily: 'ConduitBoldItalic, Arial',
      fontStyle: 'italic',
      textTransform: 'uppercase',
      fontSize: '1rem'
    },
    body2: {
    }
  },
  palette: {
    type: "light",
    primary: { main: primary },
    secondary: { main: secondary },
    tertiary: tertiary,
    quaternary: quaternary,
    toolbar: { main: toolbarBackground },
    sidebar: { main: sidebarBackground },
    componentColor : {main: componentColor},
    orange: {main: orange},
    yellow: {main: yellow},
    lightRed: {main: lightRed},
    tonalOffset:0.2,
    error: { 
      main: red.A700,
    },
  },
  shape : {
    borderRadius:14,
    borderRadiusInput:5
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiInputBase: {
      rows: 5,
    },
  },
  overrides: {
    MuiAccordion: {
      root: {
        borderRadius: '14px !important',
      },
    },
    MuiAutocomplete: {
      root: {
        "& .MuiInputLabel-formControl": {
          marginTop: "-20px",
        },
      },
      inputRoot: {
        paddingLeft: "0px !important",
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
      }
    },
    MuiTabs: {
      root : {
        borderBottom: "1px solid",
      }
    },
    MuiCardContent: {
      root: {
        "&:last-child": {
          paddingBottom: "16px !important",
        }
      }
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [conduit, conduitBoldItalic],
      },
    },
    MuiPaper: {
      root: {
        border: "none!important"
      }
    },
    MuiButton: {
      root: {
        paddingLeft: 3,
        paddingRight: 3
      }
      
    },
    MuiCheckbox: {
      colorSecondary: {
        color: "#808080",
        borderColor: componentColor,
        '&$checked': {
          color: componentColor,
        },
      },
    },
    MuiInputBase: {
      multiline: {
         padding: "11px 0px 12px",
      },
    },
    MuiOutlinedInput: {
      root: {
        "&&& $input": {
          padding: "8px 6px 8px 12px",
        }
      }
    },
    MuiRadio: {
      root: {
        color: componentColor,
      },
      colorSecondary: {
        '&$checked': {
          color: componentColor,
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "16px",
      }
    },
    MuiSwitch: {
      root: {
        width: "55px",
        height: "26px",
        padding: "0px",
        marginRight: "5px"
      },
      switchBase: {
        color: switchFalseColor,
        padding: "1px",
        "&$checked": {
          transform: "translateX(26px) !important",
          "& + $track": {
            backgroundColor: `${tertiary} !important`,
          }
        },
        "&$disabled": {
          "& + $track": {
            backgroundColor: `${switchDisabledColor}`
          }
        }
      },
      thumb: {
        color: "white",
        width: "22px",
        height: "22px",
        margin: "1px 1px 1px 2px",
      },
      track: {
        borderRadius: "20px",
        backgroundColor: `${switchFalseColor} !important`,
        opacity: "1 !important",
        "&:after, &:before": {
          color: "white",
          fontSize: "11px",
          position: "absolute",
          top: "6px"
        },
        "&:after": {
          content: "'Oui'",
          left: "8px"
        },
        "&:before": {
          content: "'Non'",
          right: "8px"
        }
      },
    }
  }
};

export default theme;