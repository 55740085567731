/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useHistory } from "react-router-dom";
import { getRoutePath } from "../components/routes";
import { UserContext } from "../contexts/UserContext";
import { isInRoles } from "../helpers/rolesHelper";

/**
 * Sécurise l'accès au composant
 * @param {React.Component} SpecificComponent Le composant à sécuriser
 * @param {boolean?} option Le type de sécurité à utiliser
 * - null  : Tout le monde peut accéder au composant
 * - true  : Seul les utilisateurs connectés peuvent accéder au composant
 * - false : Seul les utilisateurs déconnectés peuvent accéder au composant
 * @param {Array?} allowedRoles Indique quels roles peuvent accéder au composant
 */
function Auth(SpecificComponent, option, allowedRoles = []) {
  const redirectUser = (history, user) => {
    return pushTo(history, getRoutePath("home"));
  };

  const pushTo = (history, to) => {
    history.push(to);
    return null;
  };

  function AuthenticationCheck(props) {
    const { infos } = React.useContext(UserContext);
    const history = useHistory();
    const pathname = history.location.pathname;

    if (!infos) return null;

    if (!infos.isAuth) {
      if (option) {
        return pushTo(history, getRoutePath("login") + "?r=" + pathname);
      }
    } else {
      if (infos.user.needCGU || infos.user.needRGPD || infos.user.needCGV) {
        return pushTo(history, getRoutePath(`validation-notice`));
      } else if (
        allowedRoles.length !== 0 &&
        !isInRoles(infos.user.roles, allowedRoles)
      ) {
        return pushTo(history, getRoutePath(`home`));
      } else if (option === false) {
        return pushTo(history, getRoutePath(`home`));
      }
    }

    if (SpecificComponent) {
      return <SpecificComponent {...props} />;
    } else {
      return redirectUser(history, infos.User);
    }
  }

  return AuthenticationCheck;
}

export default Auth;
