import React from "react";
import { auth, getToken } from '../_actions/auth_actions';

const UserContext = React.createContext();
const logoutInfos = { isAuth: false };

function UserContextProvider({ children }) {
  const [user, setUser] = React.useState(undefined);
  const [infos, setInfos] = React.useState(logoutInfos);

  const setAndResolve = (resolver, response) => {
    setInfos(response);
    setUser(response && response.user ? response.user : null);
    resolver(response ?? logoutInfos);
  };

  const authUser = (force) =>
    new Promise((resolve) => {
      const token = getToken();
      if (!token) {
        setAndResolve(resolve, logoutInfos);
      } else if (!user || user.JwtToken !== token) {
        auth().then((r) => {
          setAndResolve(resolve, r);
        });
      } else {
        setAndResolve(resolve, infos);
      }
    });

  React.useEffect(() => {
    authUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (typeof user === "undefined") return null;

  return (
    <UserContext.Provider value={{ user, infos, authUser }}>
      {children}
    </UserContext.Provider>
  );
}
export { UserContextProvider, UserContext };
