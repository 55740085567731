import "core-js";
import React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./components/App";
import { CustomThemeProvider } from "./contexts/CustomThemeContext";
import SuspenseProvider from "./contexts/SuspenseProvider";
import "./i18n";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <Router>
    <SuspenseProvider>
      <CustomThemeProvider>
        <App />
      </CustomThemeProvider>
    </SuspenseProvider>
  </Router>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
