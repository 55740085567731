import {
  alpha,
  Backdrop,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import theme from "../../styles/theme";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: alpha(theme.palette.background.default, 0.4),
  },
  "@keyframes pulse": {
    from: { transform: "scale3d(1, 1, 1)" },
    "50%": {
      transform: "scale3d(1.05, 1.05, 1.05)",
    },
    to: {
      transform: "scale3d(1, 1, 1)",
    },
  },
}));

function Loader() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={createTheme(theme)}>
      <Backdrop className={classes.backdrop} open>
        <CircularProgress size={128} />
      </Backdrop>
    </ThemeProvider>
  );
}

export default Loader;
