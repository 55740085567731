import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import moment from "moment";
import "moment/min/locales.min";
import { initReactI18next } from "react-i18next";
import Langs from "./i18n/Langs";

// Plus d'infos et trads a partir du serveur sur le site : https://react.i18next.com/latest/using-with-hooks

let defaultLanguage = window.localStorage.lang ?? Langs[0].key;

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    initImmediate: false,
    debug: process.env.NODE_ENV !== "production",
    fallbackLng: defaultLanguage,
    keySeparator: false,
    interpolation: {
      prefix: "[[",
      suffix: "]]",
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
      wait: true,
    },
    backend: {
      loadPath:
        (process.env.REACT_APP_API_URL ?? "") + `/api/translation/[[lng]]`,
      requestOptions: {
        cache: "no-store",
      },
    },
    detection: {
      order: ["localStorage"],
      caches: ["localStorage", "cookie"],
      cookieMinutes: 80,
    },
  });

moment.locale(defaultLanguage);

export default i18n;
